import React, {useState, useRef} from 'react';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {Flex, message, Upload} from 'antd';
import type {GetProp, UploadProps} from 'antd';
import ImgCrop from "antd-img-crop";
import type {FormProps} from 'antd';
import {Button, Checkbox, Form, Input} from 'antd';
import BgImage from './bg.jpg';
import OfferImage from './offer.jpg';
import html2canvas from 'html2canvas';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};
type FieldType = {
    name?: string;
    job?: string;
};


const PosterGenerator = ({
                             image,
                             name,
                             job
                         }: {
    image?: string
    name?: string
    job?: string
}) => {
    const posterRef = useRef(null);

    const downloadPoster = () => {
        if (posterRef.current) html2canvas(posterRef.current).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'planet.png';
            link.click();
        });
    };

    return (
        <div>
            <div
                ref={posterRef}
                style={{
                    margin: '0 auto',
                    width: '808px',
                    height: '640px',
                    position: 'relative',
                }}
            >
                <img
                    src={BgImage} // 替换为你的背景图URL
                    alt="Background"
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: -1,
                    }}
                />
                {image &&
                    <img
                        src={image} // 替换为你的头像URL
                        alt="Avatar"
                        style={{
                            width: 152,
                            height: 152,
                            borderRadius: '50%',
                            position: 'absolute',
                            top: '277px',
                            left: '202px',
                            transform: 'translateX(-50%)',
                        }}
                    />
                }
                {name &&
                    <div
                        style={{
                            position: 'absolute',
                            top: 500,
                            left: 155,
                            color: '#3C90DA',
                            fontSize: '24px',
                            fontWeight: 'bold'
                        }}
                    >
                        {name}
                    </div>
                }
                {job &&
                    <div
                        style={{
                            position: 'absolute',
                            top: 550,
                            left: 155,
                            color: '#3C90DA',
                            fontSize: '24px',
                            fontWeight: 'bold'
                        }}
                    >
                        {job}
                    </div>
                }
            </div>
            <div style={{
                textAlign: 'center'
            }}>

                <Button type="primary" htmlType="button" onClick={downloadPoster} style={{
                    margin: '0 auto'
                }}>
                    下载工牌
                </Button>
            </div>
        </div>
    );
};


const OfferGenerator = ({
                            name,
                            job
                        }: {
    name?: string
    job?: string
}) => {
    const posterRef = useRef(null);

    const downloadPoster = () => {
        if (posterRef.current) html2canvas(posterRef.current).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'offer.png';
            link.click();
        });
    };

    return (
        <div>
            <div
                ref={posterRef}
                style={{
                    margin: '0 auto',
                    width: '728px',
                    height: '971px',
                    position: 'relative',
                    border: '1px solid #efefef',
                    boxShadow: 'border-box'
                }}
            >
                <img
                    src={OfferImage} // 替换为你的背景图URL
                    alt="Background"
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: -1,
                    }}
                />
                <div style={{
                    textAlign: 'center',
                    marginTop: 250,
                    fontSize: '24px',
                    textIndent: '2em',
                    lineHeight: '40px'
                }}>
                    <div>尊敬的{name}：</div>
                    <div>您好!</div>
                    <div>我们很荣幸的通知您</div>
                    <div>恭喜您已经通过我们的招聘考核</div>
                    <div>现公司正式通知您已被公司正式录用为<span style={{
                        textDecoration: 'underline'
                    }}>{job}</span></div>
                    <div>星耀行星文化传媒公司欢迎您的加入，</div>
                    <div>愿与您共创美好未来!</div>
                    <div>请您于<span style={{
                        textDecoration: 'underline'
                    }}>2024年6月15日 19:30</span>前
                    </div>
                    <div>至公司一楼正式入职报道。</div>
                    <div>同时您入职报道请提供如下资料，并保证真实有效。</div>
                    <div>1.身份证复印件一份</div>
                    <div>2.学历证书复印件一份</div>
                    <div>3.2 寸照片一张</div>
                    <div>4.浦发银行复印件一份</div>
                    <div style={{
                        marginTop: '1em',
                    }}>行星文化员工关系和企业文化部Jackey
                    </div>
                </div>


            </div>
            <div style={{
                textAlign: 'center'
            }}>

                <Button type="primary" htmlType="button" onClick={downloadPoster} style={{
                    margin: '0 auto'
                }}>
                    下载Offer
                </Button>
            </div>
        </div>
    );
};


const App: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState<string>();
    const [job, setJob] = useState<string>();
    const [imageUrl, setImageUrl] = useState<string>();
    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        console.log('Success:', values);
        setName(values.name);
        setJob(values.job);
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const uploadButton = (
        <button style={{border: 0, background: 'none'}} type="button">
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>Upload</div>
        </button>
    );
    return (
        <>

            <Form
                name="basic"
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                style={{maxWidth: 600}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="头像"
                >
                    <ImgCrop rotationSlider>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={({file, onSuccess}) => {
                                getBase64(file as FileType, (url) => {
                                    setLoading(false);
                                    setImageUrl(url);
                                });
                            }}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                        </Upload>
                    </ImgCrop>
                </Form.Item>
                <Form.Item<FieldType>
                    label="姓名"
                    name="name"
                    rules={[{required: true, message: '请输入姓名'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item<FieldType>
                    label="职位"
                    name="job"
                    rules={[{required: true, message: '请输入职位'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
            <PosterGenerator name={name} job={job} image={imageUrl}/>
            <OfferGenerator name={name} job={job}/>
        </>
    );
};

export default App;